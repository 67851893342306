/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Siema from 'siema';

// Random color
const randomColor = document.querySelector("#randomColor").value
let root = document.documentElement;
root.style.setProperty('--randomcolor', randomColor);


// Random circles when tab is unfocused
Array.prototype.randomElement = function () {
    return this[Math.floor(Math.random() * this.length)]
}
const circles = [`<svg id="circle1" data-name="circle1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245.82 60.69"><defs><style>.cls-1{fill:none;stroke:${randomColor};stroke-miterlimit:10;stroke-width:0.86px;}</style></defs><title>YumanMaintenance_Plan de travail 1</title><polyline class="cls-1" points="0.42 0.07 4.09 20.91 14.67 39.23 30.88 52.83 50.76 60.06 71.91 60.06 91.79 52.83 107.99 39.23 118.57 20.91 122.24 0.07"/><polyline class="cls-1" points="245.4 60.62 241.73 39.79 231.15 21.47 214.94 7.87 195.06 0.63 173.91 0.63 154.03 7.87 137.83 21.47 127.25 39.79 123.58 60.62"/></svg>`
                ];
// `<svg id="circle2" data-name="circle2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.49 102.73"><defs><style>.cls-1{fill:none;stroke:${randomColor};stroke-miterlimit:10;stroke-width:0.86px;}</style></defs><title>RDS/NRV</title><polyline class="cls-1" points="81.25 41.93 84.92 62.77 95.5 81.09 111.7 94.68 131.58 101.92 152.74 101.92 172.61 94.68 188.82 81.09 199.4 62.77 203.07 41.93"/><polyline class="cls-1" points="122.24 61.41 118.57 40.58 107.99 22.26 91.79 8.66 71.91 1.43 50.76 1.43 30.88 8.66 14.67 22.26 4.1 40.58 0.42 61.41"/></svg>`,
// `<svg id="circle3" data-name="circle3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.22 102.73"><defs><style>.cls-1{fill:none;stroke:${randomColor};stroke-miterlimit:10;stroke-width:0.86px;}</style></defs><title>RDS/NRV</title><polyline class="cls-1" points="1.42 1.94 5.09 22.78 15.67 41.1 31.88 54.69 51.76 61.93 72.91 61.93 92.79 54.69 108.99 41.1 119.57 22.78 123.24 1.94"/><polyline class="cls-1" points="123.24 100.78 119.57 79.95 108.99 61.63 92.79 48.03 72.91 40.8 51.76 40.8 31.88 48.03 15.67 61.63 5.1 79.95 1.42 100.78"/></svg>`

var hidden, visibilityChange; 
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
    hidden = "hidden";
    visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
} else {
    hidden = "notHidden"
}
 
function getRandom(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

var timerId = 0;
function handleVisibilityChange() {
    if (document[hidden]) {
        const timer = setInterval(() => {
            const body = document.querySelector("body")
            const circle = document.createElement('div')
            circle.innerHTML = circles.randomElement()
            circle.style.position = "fixed"
            circle.style.width = "180px"
            circle.style.top = `${getRandom(0, 100)}%`
            circle.style.left = `${getRandom(0, 100)}%`
            circle.classList.add("awayCircle")
            body.appendChild(circle)
        }, 10000);
        timerId = timer
    } else {
        clearInterval(timerId)
    }
}

const body = document.querySelector("body")
document.addEventListener('mousemove', () => {
    const circles = document.querySelectorAll(".awayCircle")
    if (circles) {
        circles.forEach((circle) => {
            body.removeChild(circle);
        })
    }

})

document.addEventListener(visibilityChange, handleVisibilityChange, false);



// Carrousel representation show
const carrousel = document.querySelector(".carrousel");
if(carrousel) {
    const mySiema = new Siema({
        selector: '.carrousel',
        duration: 200,
        easing: 'ease-out',
        perPage: 1,
        startIndex: 0,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: false,
        rtl: false,
        onInit: () => {},
        onChange: () => {},
      });  
      document.querySelector('.prev').addEventListener('click', () => mySiema.prev());
      document.querySelector('.next').addEventListener('click', () => mySiema.next());    
}

function toggleMenu() {
    const social = document.querySelector("#social")
    const socialShow = document.querySelector("#social-show")

    const hamburgerIcon = document.querySelector("#hamburger-icon")
    const blackCross = document.querySelector("#black-cross")

    const content = document.querySelector("#content")
    const header = document.querySelector("#header")
    const footer = document.querySelector("#footer")
    header.classList.toggle('hidden')
    hamburgerIcon.classList.toggle('hidden')
    blackCross.classList.toggle('hidden')
    if (content)    { content.classList.toggle('hidden') }
    if (footer)     { footer.classList.toggle('hidden') }
    if (social)     { social.classList.toggle('social-as-menu') }
    if (socialShow) { socialShow.classList.toggle('social-as-menu') }
    
    if (blackCross.classList.contains('hidden')) {
        header.classList.remove('hidden')
        if (content)    { content.classList.remove('hidden') }
        if (footer)     { footer.classList.remove('hidden') }
        if (social)     { social.classList.remove('social-as-menu') }
        if (socialShow) { socialShow.classList.remove('social-as-menu') }
    }
}

// About modal
const aboutWindow = document.querySelector("#about")
if (aboutWindow) {
    const aboutLinks = document.querySelectorAll(".about-link")
    const closeModal = document.querySelector("#cross-about")

    const content = document.querySelector("#content")
    const header = document.querySelector("#header")
    const footer = document.querySelector("#footer")
    
    aboutLinks.forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault()
            header.classList.add('hidden')
            if (content) {
                content.classList.add('hidden')
            }
            if (footer) {
                footer.classList.add('hidden')
            }
            aboutWindow.classList.remove('hidden')
        })
    })
    closeModal.addEventListener('click', () => {
        header.classList.remove('hidden')
        if (content) {
            content.classList.remove('hidden')
        }
        if (footer) {
            footer.classList.remove('hidden')
        }
        aboutWindow.classList.add('hidden')
        const socialShow = document.querySelector("#social-show")
        if (socialShow.classList.contains('social-as-menu')){
            toggleMenu()
        }
    })
}

// Mobile social menu
const hamburger = document.querySelector("#hamburger")
if (hamburger) {
    hamburger.addEventListener('click', (e) => { toggleMenu() })
}